import { Component, ViewChild, AfterViewInit, OnInit, HostListener } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { getLocalization } from '../../../config/localization';
import { URL_SERVICIOS } from '../../../config/config';
import { DataService, FacturaService, AuthenticationService } from '../../../services/service.index';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-listado-factura',
  templateUrl: './listado-factura.component.html',
})
export class ListadoFacturaComponent implements AfterViewInit, OnInit {
  // @ViewChild('myModal') myModal: NgxSmartModalService;
  //@ViewChild('content2') myModal: NgbModal;
  @ViewChild('content2') content2;
  @ViewChild('contentCancelar') contentCancelar;
  @ViewChild('contenedorCorreo') contenedorCorreo;
  @ViewChild('myGrid') myGrid: jqxGridComponent;

  showpdf = false;
  srcpdf: any;
  usuario: any;
  motivoCancelacion: any;
  correos:any;
  uuidSelect: any;
  listadoCorreos:any=[{email:'',isValid:false}];
  constructor(
    private dataService: DataService,
    private _facturaService: FacturaService,
    private modalService: NgbModal,
    private _authService: AuthenticationService,
    private httpClient: HttpClient,
    private _router: Router) { }

  ngOnInit() {
    this.usuario = this._authService.getUser();
  }
  ngAfterViewInit(): void {
    //this.getData();
    //this._smartModal.open('myModal', true);
    // this.ngxSmartModalService.getModal('myModal').open();
  }
  columns: any[] =
    [
      { text: 'Folio', datafield: 'folioCompleto', columntype: 'textbox', filtertype: 'input' },
      { text: 'Folio Fiscal', datafield: 'uuid' },
      { text: 'Nombre Cliente', datafield: 'razonSocialReceptor' },
      { text: 'Importe', datafield: 'total' },
      { text: 'FechaEmision', datafield: 'fechaEmision', filtertype: 'range', width: 210, cellsalign: 'right', cellsformat: 'yyyy-MM-dd' },
      //{ text: 'Estatus', datafield: 'estatusSat' },
      {
        text: "",
        datafield: 'opciones',
        width: 150,
        cellsalign: 'right',
        filterable: false,
        editable: false,
        sortable: false,
        createwidget: (row: any, column: any, value: string, htmlElement: HTMLElement): void => {
          // if (this.aclService.can('usuario:eliminar')) {
          //boton Eliminar
          const buttonEliminar = document.createElement('button');
          buttonEliminar.id = 'eliminar' + row.bounddata.id;
          buttonEliminar.className = 'btn btn-danger btn-sm mr-1 pull-right';
          buttonEliminar.innerHTML = "<i class='fa fa-trash'></i>";
          buttonEliminar.addEventListener('click', (event) => {
            this.cancelarFactura(row.bounddata.uuid)
          });
          htmlElement.appendChild(buttonEliminar);
          //Reutilizar factura
          const btnReciclar = document.createElement('button');
          btnReciclar.id = 'reutilizar' + row.bounddata.id;
          btnReciclar.className = 'btn btn-success btn-sm mr-1 pull-right';
          btnReciclar.innerHTML = "<i class='fa fa-paste'></i>";
          btnReciclar.addEventListener('click', (event) => {
            //this.cancelarFactura(row.bounddata.uuid)
            this._router.navigate(['facturaNueva/' + row.bounddata.id]);
          });
          htmlElement.appendChild(btnReciclar);

          // }
          //Boton Imprimir
          const buttonImprimir = document.createElement('button');
          buttonImprimir.id = 'imprimir' + row.bounddata.id;
          buttonImprimir.className = 'btn btn-warning btn-sm mr-1 pull-right';
          buttonImprimir.innerHTML = "<i class='fa fa-print'></i>";
          buttonImprimir.addEventListener('click', (event) => {
            this.imprimir(row.bounddata.uuid)
          });
          htmlElement.appendChild(buttonImprimir);

          const btnCorreo = document.createElement('button');
          btnCorreo.id = 'correo' + row.bounddata.id;
          btnCorreo.className = 'btn btn-info btn-sm mr-1 pull-right';
          btnCorreo.innerHTML = "<i class='fa fa-envelope'></i>";
          btnCorreo.addEventListener('click', (event) => {
            this.enviarCorreo(row.bounddata.uuid)
          });
          htmlElement.appendChild(btnCorreo);
          
        }, initwidget: (row: number, column: any, value: any, htmlElement: HTMLElement): void => {
        }
      }
    ];
  source: any =
    {
      datatype: 'json',
      datafields: [
        { name: 'folioCompleto', type: 'string' },
        { name: 'razonSocialReceptor', type: 'string' },
        { name: 'total', type: 'string' },
        { name: 'estatusSat', type: 'string' },
        { name: 'fechaEmision', type: 'date' },
        { name: 'uuid', type: 'string' },
        { name: 'id', type: 'string' }
      ],
      id: 'id',
      pagesize: 20,
      totalrecords: 0,
      cache: false,
      url: URL_SERVICIOS + 'factura/listado/vigentes'
    };

  dataAdapter: any = new jqx.dataAdapter(this.source, {
    downloadComplete: (data, status, xhr) => {
      this.source.totalrecords = data.TotalRows;
      if (!this.source.totalrecords) {
        this.source.totalrecords = data.TotalRows;
      }
    }
  });
  rendergridrows(data) {
    return data.data;
  }

  enviarCorreo(id: any) {
    this.uuidSelect=id;
    this.modalService.open(this.contenedorCorreo, {
        size: "sm",
        centered: true,
        backdrop: false,
        keyboard: false,
        //closeOnOutsideClick: false,
        //backdropClass: "modal-backdrop"
      }).result.then((result) => {
        // this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  imprimir(id: any) {
    Swal.showLoading();
    //this.myModal.open('')
    this._facturaService.imprimir(id).subscribe((data: any) => {
      Swal.close();
      //this.ngxSmartModalService.getModal('myModal').open();
      this.srcpdf = data.pdf;
      this.showpdf = true;
      this.modalService.open(this.content2, {
        size: "lg",
        centered: true,
        backdrop: false,
        keyboard: false,
        //closeOnOutsideClick: false,
        //backdropClass: "modal-backdrop"
      }).result.then((result) => {
        // this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });

    })
  }
  cancelando(obj) {
    //Swal.showLoading();
    this._facturaService.cancelar(obj).subscribe(data => {
      // console.log(data);
      //Swal.hideLoading();
      if (data.response) {
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Factura Cancelada.',
          showConfirmButton: false,
          timer: 1000
        });
      }
      return data;
    });
  }
  procesoEnvioCorreo(){
    Swal.showLoading();
    let correos=this.listadoCorreos.map(item=>item.email);
    this._facturaService.enviarCorreo({uuid:this.uuidSelect,correos:correos}).subscribe(res=>{
      Swal.close();
      //console.log(data);
      Swal.fire({
        position: 'top-end',
        type: 'success',
        title: 'Correo enviado.',
        showConfirmButton: false,
        timer: 1000
      });
    })
  }
  procesoCancelacion() {
    let obj = { uuid: this.uuidSelect, motivoCancelacion: this.motivoCancelacion, empresaId: this.usuario.empresaId };
    this.modalService.dismissAll();
    Swal.showLoading();
    this._facturaService.cancelar(obj).subscribe(data => {
      Swal.close();
      if(data.response){
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: data.message
        });
        this.myGrid.updatebounddata();
      }else{
        Swal.fire({
          position: 'top-end',
          type: 'error',
          title: data.message
        });
      }
      
    });
  }
  cancelarFactura(uuid) {
    this.uuidSelect = uuid;
    this.modalService.open(this.contentCancelar, {
      size: "sm",
      centered: true,
      backdrop: false,
      keyboard: false,
      //closeOnOutsideClick: false,
      //backdropClass: "modal-backdrop"
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  //this.myGrid.updatebounddata();
  onEvent(event: any): void {
    this.myGrid.updatebounddata();
  };
  validarCorreo(item){
    console.log(item)
    var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (item.email.match(EMAIL_REGEX)){
      item.isValid=true;
     }else{
       item.isValid=false;
     }
  }

  nuevoCorreo(){
    var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let bandera=false;
    for(let item of this.listadoCorreos){
      if (!item.email.match(EMAIL_REGEX)){
       bandera=true;
      }
    }
    if(!bandera){
      this.listadoCorreos.push({email:'',isValid:false});
    }
  }
  /* onSort(){
     this.myGrid.updatebounddata();
   }
   onFilter(){
     this.myGrid.updatebounddata();
   }*/
  /*cancelarFactura(){

  }*/
}
